export const HTTP_VERB = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
    PATCH: 'PATCH',
};

export const SALES_UNITS = {
    RESI: {
        code: "RESI",
        desc: "Residential"
    },
    SMB: {
        code: "SMB",
        desc: "Small Medium Business"
    },
    MOB: {
        code: "MOB",
        desc: "RESI-Mobile"
    },
    SMB_MOB: {
        code: "SBMOB",
        desc: "SMB-Mobile"
    }
};